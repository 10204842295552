import React from "react"
import { Link } from 'gatsby';

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';

// Image imports
import probate from '../images/probate-photo.jpg';

// Routes for URLs
import * as ROUTES from '../constants/routes';

const Probate = () => {
  return (
    <Layout>
        <SEO 
            title="Probate Attorney in Kansas City | Mike Martin Law"
            description="When probate cases become complicated or adversarial, estate planning attorneys often refer their clients to Mike Martin Law."
        />
        <Card>
            <PageHeader 
                title="Probate"
                image={probate}
                alte="Concerned looking woman"
            />
            <div className="grid col-span-1 sm:grid-cols-12 my-8">
                <div className="sm:col-span-8">
                    <p>Mike Martin has a longstanding passion for probate law, and his prowess as a probate lawyer has earned him a solid reputation for getting results. When probate cases become complicated or adversarial and require an experienced litigator, estate planning attorneys often refer their clients to Mike Martin Law.</p>
                    <h3 className="section-header">What is Probate?</h3>
                    <p>Probate is the process by which a deceased person's property, known as the "estate," is passed to his or her heirs and legatees (people named in the will). The entire process is supervised by the probate court.</p>
                    <p>Dealing with probate can be stressful and complicated, and becomes even more so when there is a contested will or an heir dispute.</p>
                    <p>Mike Martin is particularly skilled in troubleshooting cases where probate doesn’t go smoothly. In addition, Mike Martin’s experience in <Link to={ROUTES.BUSINESS} className="text-secondary">business law</Link> comes into play when probate includes business holdings.</p>
                    <p className="font-semibold">Areas of probate expertise include:</p>
                    <ul>
                        <li>Disputed estates (sometimes referred to as “will contests”)</li>
                        <li>Family settlement agreements</li>
                        <li>Non-probate transfers</li>
                        <li>Issuance of Letters of Administration</li>
                        <li>Guardianship and conservatorships</li>
                        <li>Restoration of capacity</li>
                        <li>Minors conservatorships</li>
                        <li>Simplified Administration</li>
                    </ul>
                    <h3 className="section-header">Why would I need a probate attorney?</h3>
                    <p>If an estate is complicated, if the deceased did not have a will, of if the estate is not in good order, you may need legal assistance to sort things out.</p>
                    <p>If there is an heir dispute or other type of will contest, probate can become incredibly stressful and time-consuming.</p>
                    <p>If you find yourself involved in a disputed estate, you may quickly reach the point where you need a probate attorney.</p>
                    <p>Probate responsibilities ultimately fall on whoever was appointed executor or personal representative in the deceased person’s will.</p>
                    <p>Matters can be more complicated in the absence of a will, because it may not be clear who has the responsibility.</p>
                    <p className="font-semibold">The rules of estate administration include the following steps:</p>
                    <ol>
                        <li>Filing the will and petition at the probate court in order to be appointed executor or personal representative.</li>
                        <li>Marshaling, or collecting, the assets.</li>
                        <li>Paying bills and taxes.</li>
                        <li>Filing tax returns.</li>
                        <li>Distributing property to the heirs and legatees.</li>
                        <li>Filing a final account.</li>
                    </ol>
                    <p>Each of these steps can be complicated and confusing for grieving survivors and may give rise to conflict. Taking an estate through probate can take months or years, even in the best of circumstances.</p>
                    <p>The steps in a probate process will be easier if the deceased kept good records of assets and liabilities, thus shortening the process and reducing the legal bill.</p>
                    <p className="font-bold">If you need help with a contested probate case or have questions about probate administration, <Link to={ROUTES.CONTACT} className="text-secondary">contact Mike Martin</Link> to get the legal expertise you need.</p>
                    <p>Mike can also assist you with <Link to={ROUTES.TRUSTS} className="text-secondary">trusts</Link> and other aspects of <Link to={ROUTES.ESTATES} className="text-secondary">estate planning</Link> that may allow for probate avoidance and help protect your heirs from probate problems.</p>
                </div>
            </div>
        </Card>
    </Layout>
  )
};

export default Probate;